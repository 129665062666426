"use client"

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import Link from "next/link"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"
import { PasswordInput } from "../(components)/password-input"
import { useRouter } from "next/navigation"
import { login } from "../actions"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
// import { useUrlMaker } from "@/utils/useUrlMaker"

export const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "ce champ est requis" })
    .email("Le format attendu est: texte@nom.domaine. Ex: Luc.boulanger@gmail.com"),
  password: z.string().min(1, { message: "ce champ est requis" }),
  rememberMe: z.boolean(),
})

export default function LoginPage() {
  // const { app } = useUrlMaker()
  const router = useRouter()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // TODO: remember me input

    const data = {
      email: values.email as string,
      password: values.password as string,
      rememberMe: values.rememberMe as boolean,
    }
    const { error: _error, data: _data } = await login(data)

    if (_error) {
      console.error(_error)
      form.formState.errors.password = {
        message: "Adresse mail ou mot de passe incorrecte",
        type: "custom",
      }
      form.formState.errors.email = {
        message: " Adresse mail ou mot de passe incorrecte ",
        type: "custom",
      }
    } else if (_data) {
      // NOK: rememberMe
      //values.rememberMe ? localStorage.setItem('user', JSON.stringify(data)) : null
      /*if (!values.rememberMe) {
                sessionStorage.setItem('supabase.auth.token', JSON.stringify(data))
            } */
      router.push("/fr/app/task")
    }
  }

  return (
    <div className="pt-8 pb-12 px-16 bg-white rounded-md w-[488px]">
      <h1 className="pb-8 text-[32px] font-bold text-[#3D3C3C] ">Connexion</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel>Mot de passe</FormLabel>
                <FormControl>
                  <PasswordInput id="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="rememberMe"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <div className="flex space-x-2">
                  <FormControl>
                    <Switch
                      id="rememberMe"
                      className="data-[state=checked]:bg-[#005299]"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="self-center">Se souvenir de moi</FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full bg-[#005299] hover:bg-[#005299]/90">
            Se connecter
          </Button>
          <Button className="py-3 text-[#005299]" type="button" variant="link">
            <Link href="/fr/password-forgotten">Mot de passe oublié ?</Link>
          </Button>
        </form>
      </Form>
    </div>
  )
}
